.category-menu {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .footer {
    margin-top: 15rem;
  }
}
