.cart-icon {
  width: 4.5rem;
  height: 4.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 1rem;
  background-color: white;
  box-shadow: 0 2rem 2.5rem -0.5rem rgba(0, 0, 0, 0.1),
    0 1rem 1rem 0px rgba(0, 0, 0, 0.04);

  .shopping-icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .item-count {
    position: absolute;
    font-size: 1rem;
    font-weight: bold;
    bottom: 1rem;
  }

  &:hover {
    box-shadow: 0 2rem 2.5rem -0.5rem rgba(0, 0, 0, 0.1),
      0 1rem 1rem -0.8rem rgba(0, 0, 0, 0.04);
  }
}
