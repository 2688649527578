.contact-page {
  h1,
  h2 {
    margin-bottom: 3rem;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .footer {
    margin-top: 15rem;
  }
}
