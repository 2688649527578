.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;

  .categories-container {
    display: flex;
    justify-content: center;
  }
  .categories-overview {
    position: relative;
  }

  .hero-area {
    height: 90vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0rem 2rem 0 2rem;
    gap: 3rem;
    background-image: url("https://i.ibb.co/fFGN5Qc/hero-3.jpg");
    background-size: cover;
    background-position: left;
    color: white;

    h1 {
      font-size: 5rem;
    }

    p {
      font-size: 2rem;
    }

    @media screen and (max-width: 800px) {
      height: 70vh;
      background-position: left;
      padding-top: 5rem;
    }

    .custom-button {
      font-size: 1.6rem;
      height: 5rem;
      padding-right: 2.5rem;
      padding-left: 2.5rem;
      border: none;
      border-radius: 0;
      font-family: inherit;
      border-radius: 1rem;
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .homepage-contents {
    width: 100%;
    text-align: center;
  }
}
