.product-page {
  display: flex;
  flex-direction: row-reverse;
  padding: 5vw;
  margin-top: 5vh;
  .image-container {
    width: 50%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5rem;
    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
    }

    @media only screen and (max-width: 800px) {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 800px) {
    .image-container {
      width: 100%;
      padding: 0;
    }
  }
  .details-container {
    width: 50%;
    .description {
      margin-top: 2rem;
      margin-bottom: 3rem;
    }

    select {
      height: 5rem;

      border: 2px solid #e9edf0;

      border-radius: 0.8rem;
      padding: 0.8rem 1.8rem;
      font-size: 1.6rem;
      margin-right: 1rem;
      cursor: pointer;
    }

    .quantity {
      font-size: 1.6rem;
      height: 5rem;
      width: 6rem;
      border: 2px solid #e9edf0;
      border-radius: 0.8rem;
      background-color: transparent;
      text-align: center;
      margin-right: 1rem;
      margin-bottom: 10px;
      padding: 0.8rem 0.6rem 0.5rem 1.8rem;
    }

    .get-help {
      color: rgba(7, 18, 42, 0.76);
      background-color: #fff7e9;
      padding: 2rem;
      border-radius: 1rem;
      text-align: center;
      margin-top: 3rem;
    }
    @media only screen and (max-width: 800px) {
      width: 100%;
      margin-bottom: 3rem;
    }
  }

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
}
