.menu {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: scroll;
  z-index: 9;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e6ecf1;
  padding-top: 3rem;
  align-items: center;
  top: 0;
  left: 0;
  background-color: white;

  .logo {
    box-shadow: 0px 2px 8px rgba(var(--color-primary), 0.5);
    width: 4rem;
    height: 4rem;
  }

  .menu-contents {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .menu-title {
      margin: 3rem 0;
      text-transform: capitalize;
    }
    .menu-link {
      font-size: 1.6rem;
      font-weight: bold;
      margin-bottom: 1.5rem;
      color: #959697;
      transition: 0.3s;

      &:hover {
        color: #252527;
      }
    }

    .active-menu-link {
      color: #252527;
      font-weight: bold;
    }

    .social-links {
      margin-top: 2rem;
      display: flex;
      justify-content: space-around;
      width: 20rem;
      padding: 2rem;

      svg {
        fill: #6e6d7a;
        height: 2rem;
        width: 2rem;
      }
    }
  }

  .close-button {
    width: 4rem;
    height: 4rem;
    position: absolute;
    z-index: 10;
    top: 3.5rem;
    right: 2rem;
    cursor: pointer;
  }
}
