// COLOR VARIABLES

:root {
  --color-primary: #17f1d7;
  --black: #252527;
  --light-grey: #e6ecf1;

  // FONT VARIABLES
  --font-primary: "Manrope";
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 10px/16px = 62.5% -> 1rem = 10px
}

body {
  font-family: var(--font-primary);
  padding: 2rem 0;
  font-size: 1.6rem;
  line-height: 1.6;
  color: #252527;

  // @media screen and (max-width: 800px) {
  //   padding-top: 0.5rem;
  // }
}
a {
  text-decoration: none;
  color: #252527;
}

h1,
h2,
h3 {
  font-family: "Manrope";
}

h1 {
  font-size: 4rem;
}

* {
  scrollbar-width: thin;
  scrollbar-color: grey white;
}

*::-webkit-scrollbar {
  width: 1.2rem;
}
*::-webkit-scrollbar-track {
  background: white;
}
*::-webkit-scrollbar-thumb {
  background-color: grey;
  border: 3px solid white;
  border-radius: 1rem;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: var(--black);
}
