.shop-sidebar {
  width: 18vw;
  height: 100%;

  @media screen and (max-width: 1000px) {
    display: none;
  }

  .sidebar-wrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 18vw;
    border-right: 1px solid #e6ecf1;
    padding-right: 10rem;
    height: 100vh;
    padding-left: 3rem;
    padding-top: 3rem;
    top: 0;
    background-color: white;

    .logo {
      box-shadow: 0px 2px 8px rgba(var(--color-primary), 0.5);
      width: 4rem;
      height: 4rem;
    }

    .sidebar-title {
      margin: 3rem 0;
      text-transform: capitalize;
    }
    .sidebar-link {
      font-size: 1.6rem;
      font-weight: bold;
      margin-bottom: 1.5rem;
      color: #959697;
      transition: 0.3s;

      &:hover {
        color: #252527;
      }
    }

    .active-sidebar-link {
      color: #252527;
      font-weight: bold;
    }
  }

  .social-links {
    align-self: flex-end;
    display: flex;
    justify-content: space-around;
    width: 18vw;
    padding: 2rem;
    position: absolute;
    bottom: 0;
    right: 0;

    svg {
      fill: #6e6d7a;
      height: 2rem;
      width: 2rem;
    }
  }
}
