.checkout-item {
  max-width: 100%;
  max-height: 20rem;
  background-color: #fff;
  display: flex;
  padding: 1rem;
  font-size: 1.6rem;
  align-items: center;
  box-shadow: 0 0px 20px 0 rgba(116, 129, 141, 0.15);

  border-radius: 2rem;
  margin-bottom: 2rem;

  .image-container {
    width: 25%;
    height: 20rem;
    margin-right: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .item-details {
    .name {
      font-weight: bold;
    }

    .size {
      color: #959697;
    }
    .quantity {
      display: flex;
      justify-content: space-around;
      border: 2px solid #e9edf0;
      border-radius: 1rem;
      margin-top: 2vw;
      width: 10rem;
      padding: 0.5rem 1rem;

      .quantity-modifier {
        cursor: pointer;
      }

      .value {
        margin: 0 1rem;
      }
    }
  }

  .remove-button {
    margin: 0 2vw 0 auto;

    cursor: pointer;
  }
}
