.custom-button {
  font-size: 1.6rem;
  height: 5rem;
  padding: 0 2.5rem;
  border: 1px solid #e6ecf1;
  font-weight: 600;
  border-radius: 0.8rem;
  color: #252527;
  font-family: Manrope;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  transition: border-color 0.3s;

  &:hover {
    border-color: #d1d3d4;
  }

  &.primary {
    background-color: var(--color-primary);
    border: unset;
    transition: background-color 0.3s;
    box-shadow: 0px 2px 8px rgba(var(--color-primary), 0.4);

    &:hover {
      background-color: var(--color-primary);
      box-shadow: 0px 15px 20px rgba(#17f1d7, 0.4);
    }
  }

  &.black {
    background-color: #252527;
    color: #ffffff;
    border: unset;
    transition: background-color 0.3s;

    &:hover {
      background-color: #39393b;
    }
  }

  &.active {
    background-color: #e6ecf1;
  }
}
