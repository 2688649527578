.search-dropdown {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding: 10rem 1rem 2rem 2rem;
  box-shadow: 0 10px 30px rgba(#414856, 0.15);
  border-radius: 1rem;
  background-color: white;
  top: 0;
  left: 0;
  z-index: 5;

  .close-button {
    width: 4rem;
    height: 4rem;
    position: absolute;
    z-index: 10;
    top: 3.5rem;
    right: 2rem;
    cursor: pointer;
  }
  .search-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .search-bar {
      background: none;
      width: 60%;
      height: 5rem;
      padding: 0 2rem 0 5rem;
      font-size: 1.5rem;
      font-family: inherit;
      outline: none;
      border: none;
      border-bottom: 1px solid #e6e7e8;
      transition: 0.3s;

      &:focus {
        border-bottom: 1px solid #252527;
      }

      @media screen and (max-width: 800px) {
        width: 80%;
      }
    }
  }
  .results-container {
    margin-top: 3rem;
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    .results-wrapper {
      width: 50%;
      @media screen and (max-width: 800px) {
        width: 80%;
      }
    }
  }

  .result-item {
    padding: 0.8rem 0;
    color: grey;
    font-weight: bold;
    font-size: 1.5rem;

    &:hover {
      color: #262628;
    }
  }
  .products-heading {
    margin-top: 1.1rem;
  }

  .in-collection {
    font-weight: normal;
  }

  .no-results {
    margin: 3rem auto;
    text-align: center;
  }
}
