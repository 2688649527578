.checkout-page {
  width: 90vw;
  margin: 0 auto;
  padding: 5vw;

  h1 {
    text-align: center;
    margin-bottom: 3rem;
  }

  h2 {
    margin-bottom: 3rem;
  }

  .checkout-contents {
    display: flex;
    gap: 3vw;

    .checkout-items-container {
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex: 3;
    }

    .form-container {
      flex: 2;
      margin-left: 3rem;
    }

    .checkout-form {
      display: flex;
      flex-direction: column;

      label {
        font-size: 1.4rem;
        color: grey;
        font-weight: bold;
        margin-bottom: 0.8rem;
      }

      input,
      textarea {
        border: 2px solid var(--light-grey);
        border-radius: 0.8rem;
        line-height: 2.4rem;
        font-size: 1.6rem;
        padding: 0.8rem;
        margin-bottom: 2rem;
        font-family: var(--font-primary);
        width: 100%;
      }

      textarea {
        height: 15rem;
      }

      input:hover,
      textarea:hover {
        border: 2px solid black;
      }
    }
  }

  .empty-message {
    color: #959697;
    text-align: center;
    margin: 10rem 0;

    h2 {
      margin-bottom: 3rem;
    }
    a {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 720px) {
  .checkout-contents {
    flex-direction: column;

    .checkout-items-container,
    .form-container {
      flex: 1;
    }

    .form-container {
      margin-left: 0;
    }

    input,
    textarea {
      width: 100%;
    }
  }
}
