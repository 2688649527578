.collection-page {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  width: 82%;
  padding: 5vw;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  .title {
    font-size: 38px;
    margin: 0 auto 30px;
  }

  .filter-area {
    .filter-group {
      .filter-label {
        font-weight: 600;
        margin-right: 2rem;
      }
      margin-bottom: 2rem;
    }
    margin-bottom: 2rem;
  }

  .items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-gap: 2vw;
  }

  .no-products-message {
    width: 100%;
    text-align: center;
    margin-top: 3rem;
  }
}
