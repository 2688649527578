.category-page {
  position: absolute;
  right: 0;
  width: 82%;
  padding: 5vw;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  h1 {
    text-transform: capitalize;
    margin-bottom: 3rem;
  }
}
