.loader-overlay {
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes moveWaves {
  to {
    transform: translateX(-25%);
  }
}

@-webkit-keyframes moveWaves {
  to {
    transform: translateX(-25%);
  }
}

.waves-container {
  width: 5rem;
  overflow: hidden;

  .waves {
    fill: #252527;
    animation: moveWaves 1s linear infinite normal none;
    width: 150%;
  }
}
