.collection-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 350px;
  align-items: center;
  position: relative;
  margin-bottom: 2rem;
  border-radius: 10px;
  transition: 0.3s;
  overflow: hidden;

  box-shadow: 0 5px 20px 0 rgba(116, 129, 141, 0.1);

  &:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 0px 0px -5px rgba(0, 0, 0, 0.04);
  }

  .image-container {
    width: 100%;
    height: 80%;
    padding: 1rem;

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }

  .collection-footer {
    width: 100%;
    min-height: 5%;
    font-size: 1.6rem;
    padding: 1.6rem 2rem 2rem;
  }
}
