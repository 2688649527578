.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
  font-size: 1.4rem;
  margin-top: auto;
  color: #aaa;
}
