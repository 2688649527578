.cart-item {
  height: 10rem;
  width: 100%;
  display: flex;
  margin-bottom: 1.5rem;

  .image {
    width: 25%;
    border-radius: 1rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .item-details {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 1rem 2rem;
    font-size: 1.5rem;

    .name {
      font-weight: bold;
    }
    .quantity {
      color: #959697;
    }

    .size {
      color: #959697;
    }
  }
}
