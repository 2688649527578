.menu-item {
  width: 30rem;
  height: 240px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  margin: 0 7.5px 15px;
  overflow: hidden;

  &:hover {
    cursor: pointer;

    & .image-container {
      transform: scale(1.1);
      transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    }

    & .content {
      opacity: 0.9;
    }
  }

  &.large {
    height: 380px;
  }

  &:first-child {
    margin-right: 7.5px;
  }

  &:last-child {
    margin-left: 7.5px;
  }

  .image-container {
    background-color: grey;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: brightness(0.8);
    }
  }

  .content {
    color: white;
    position: absolute;

    .title {
      font-weight: bold;
      word-wrap: break-word;
      margin-bottom: 6px;
      font-size: 3rem;
      color: white;
      text-transform: capitalize;
      text-shadow: 2px 2px 4px rgba(#000000, 0.5);
    }
  }
}
