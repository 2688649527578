.navbar {
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  padding: 0 2vw;

  @media screen and (max-width: 800px) {
    font-size: 1.4rem;
  }

  .logo-container {
    height: 100%;
    width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      box-shadow: 0px 2px 8px rgba(var(--color-primary), 0.5);
    }
  }

  .links-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .search-icon {
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      padding-top: 1rem;
      margin-right: 3rem;
    }

    .nav-link {
      padding: 2rem 0.2rem;
      margin-right: 3rem;
      font-weight: bold;
      cursor: pointer;
      transition: 0.3s;
      line-height: 2rem;
      height: 5rem;
      border-bottom: 2px solid transparent;
      color: #6e6d7a;

      &:hover {
        border-bottom-color: #252527;
      }

      @media screen and (max-width: 800px) {
        margin-right: 1.5rem;
      }
    }

    .active-link {
      border-bottom-color: var(--color-primary);
    }
  }

  .menu-icon {
    padding-top: 0.7rem;
    margin-left: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
