.filter-tag {
  font-size: 1.4rem;
  height: 3.6rem;
  padding: 0 1.6rem;
  border: 2px solid #e9edf0;
  font-weight: 600;
  border-radius: 1rem;
  color: #252527;
  font-family: Manrope;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  transition: border-color 0.3s;
  margin-right: 1rem;
  margin-top: 1rem;
  min-width: 6rem;

  &:hover {
    border-color: #252527;
  }

  &:focus {
    outline: none;
  }

  &.active {
    background-color: #e9edf0;
    font-weight: bold;
    cursor: default;

    &:hover {
      border-color: #e9edf0;
    }
  }
}
