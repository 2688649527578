.cart-dropdown {
  position: absolute;
  width: 35rem;
  height: 50rem;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(#414856, 0.15);
  border-radius: 1rem;
  background-color: white;
  top: 9rem;
  right: 4rem;
  z-index: 5;

  @media screen and (max-width: 430px) {
    width: 95%;
    right: 0.8rem;
  }

  .empty-message {
    font-size: 1.8rem;
    margin: 5rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 10rem;
  }

  .cart-items {
    height: 40rem;
    display: flex;
    flex-direction: column;
    overflow: scroll;
  }

  button {
    margin-top: auto;
  }
}
