.categories-overview {
  position: absolute;
  right: 0;
  width: 82%;
  overflow: auto;
  padding: 5vw 5vw 0;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  h1 {
    margin-bottom: 3rem;
  }
  .category-items {
    display: flex;
    flex-wrap: wrap;
  }
}
